import {useEffect, useState} from "react";
import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {FilterText} from "../../filter/FilterText";
import WindowUtil from "../../../util/WindowUtil";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";

export const CountrySelectorModal = (props) => {

    const {shown} = props;
    const {multiSelect} = props;
    const {items} = props;
    const {callback} = props;

    const [countries, setCountries] = useState([]);
    const [networkInFlight, setNetworkInFLight] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        if (shown) {
            setSelectedItems(items ? items : []);

            fetchCountriesFromNetwork();

            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();

            setCountries([]);
            setNetworkInFLight(false);
            setSelectedItems([]);
            setKeyword("");
            setForceDismiss(false);
        }
    }, [shown]);

    function handleCallback(action) {
        if (callback) {
            callback(action, selectedItems);
        }
    }

    function selectItem(country) {
        let items = [...selectedItems];

        let index = isSelected(country);
        if (index === false) {
            items.push(country);
        } else {
            items.splice(index, 1);
        }

        setSelectedItems(items);
    }

    function isSelected(country) {
        for (let i = 0; i < selectedItems.length; i++) {
            if (selectedItems[i].code === country.code) {
                return i;
            }
        }
        return false;
    }

    // Network

    function fetchCountriesFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFLight(true);

        Axios.post(ENDPOINTS.country.getCountries, {})
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setCountries(resp.data.data);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setNetworkInFLight(false);
            })
    }

    if (!shown) {
        return [];
    }

    let listContent = [];

    countries.forEach((country) => {
        console.log("COUNTRY. Keyword: " + keyword);
        if (!keyword || keyword === "" || country.name.toLowerCase().includes(keyword)) {
            let checkbox = [];
            let clickListener = null;
            if (multiSelect) {
                let selectedIndex = isSelected(country);
                checkbox = (<input type={"checkbox"} checked={selectedIndex !== false} onChange={() => selectItem(country)}/>)
            } else {
                clickListener = () => {
                    selectItem(country);
                    setForceDismiss(true);
                }
            }

            listContent.push(
                <li className={"list-group-item"} onClick={clickListener}>
                    <label>
                        {checkbox} {country.name}
                    </label>
                </li>
            )
        }
    });

    if (networkInFlight) {
        listContent.push(
            <div className={"mt-2"}>
                <LoadingSpinner inline={true} small={true} />
            </div>
        )
    }

    listContent = (
        <ul className={"list-group mt-2"}>
            {listContent}
        </ul>
    );

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("countries.selector_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <FilterText callback={setKeyword} />
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-12"}>
                    {listContent}
                </div>
            </div>

        </Offcanvas>
    )

}