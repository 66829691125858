import Rosetta from "../../rosetta/Rosetta";
import {useEffect, useRef, useState} from "react";
import { v4 as uuidv4 } from "uuid";

export const OffcanvasGravity = {
    START : "offcanvas-start",
    END : "offcanvas-end",
    BOTTOM : "offcanvas-bottom",
    TOP : "offcanvas-top"
}

export const OffcanvasActions = {
    CLOSE : "close"
};

export const Offcanvas = (props) => {

    const {shown} = props;
    const {forceDismiss} = props;
    const {allowDismiss} = props;

    const [id, setId] = useState(uuidv4());
    const [isDismissing, setIsDismissing] = useState(false);

    useEffect(() => {
        let dismissTimer = undefined;
        if (isDismissing) {
            clearTimeout(dismissTimer);
            dismissTimer = setTimeout(() => {
                setIsDismissing(false);
                if (props.callback !== undefined) {
                    props.callback(OffcanvasActions.CLOSE);
                }
            }, 200);
        }

        return () => {
            clearTimeout(dismissTimer);
        }
    }, [isDismissing]);

    useEffect(() => {
        if (forceDismiss === true && !isDismissing) {
            setIsDismissing(true);
        }
    }, [forceDismiss]);

    function handleDismiss() {
        if (allowDismiss === undefined || allowDismiss) {
            setIsDismissing(true)
        }
    }

    if (!shown) return [];

    // We mutate key here to "recreate" the element in the DOM to allow us to replay the entry animation on exit
    let elemKey = id + "_standard";
    let dismissClass = "";
    if (isDismissing) {
        dismissClass = " dismissing";
        elemKey = id + "_dismiss";
    }

    let offcanvasTitle = "";
    if (props.title !== undefined) {
        offcanvasTitle = props.title;
    }

    let offcanvasClass = OffcanvasGravity.START;
    if (props.gravity !== undefined) {
        offcanvasClass = props.gravity;
    }

    let offcanvasBody = [];
    if (props.children !== undefined) {
        offcanvasBody = props.children;
    }

    let offcanvasFooter = [];
    if (props.footerContent !== undefined) {
        offcanvasFooter = (
            <div className={"offcanvas-footer"}>
                {props.footerContent}
            </div>
        );
    }

    console.log("CANVAS RENDER: " + elemKey);

    return (
        <div className={"generic-offcanvas " + dismissClass} key={elemKey}>
            <div className={"offcanvas-scrim"} />

            <div className={"offcanvas " + offcanvasClass}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">{offcanvasTitle}</h5>
                    <button type="button"
                            className="btn-close text-reset"
                            aria-label={Rosetta.string("common.close")}
                            onClick={handleDismiss} />
                </div>
                <div className="offcanvas-body">
                    {offcanvasBody}
                </div>

                {offcanvasFooter}
            </div>
        </div>
    );

}