import {PlaceholderText} from "./PlaceholderText";

export const ContentOrPlaceholder = (props) => {

    let className = "";
    if (props.className !== undefined) {
        className = props.className;
    }

    let showPlaceholder = false;
    if (props.showContent !== undefined) {
        showPlaceholder = !props.showContent;
    }

    let contentView = [];
    if (!showPlaceholder) {
        if (props.children !== undefined) {
            contentView = props.children;
        }
    } else {
        contentView = (<PlaceholderText className={className} />);
        if (props.placeholder !== undefined) {
            contentView = props.placeholder;
        }
    }

    return contentView;
}