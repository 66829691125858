import TableComponent, {TablePaginator} from "./TableComponent";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API} from "../../network/API";

export const AutoTableActions = {
    PAGE_CHANGED : "pageChanged",
    ROW_CLICKED : "rowClicked",
    NETWORK_IN_FLIGHT : "networkInFlight",
    ERROR : "error"
};

export const AutoTable = (props) => {

    const {url} = props;
    const {data} = props;
    const {method} = props;
    const {page} = props;
    const {pageSize} = props;
    const {callback} = props;

    const [tableData, setTableData] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        if (url) {
            fetchTableDataFromNetwork();
        }
    }, []);

    useEffect(() => {
        fetchTableDataFromNetwork();
    }, [url, data, method, page, pageSize])

    useEffect(() => {
        handleCallback(AutoTableActions.NETWORK_IN_FLIGHT, networkInFlight);
    }, [networkInFlight]);

    function handleCallback(action, data) {
        if (callback) {
            callback(action, data);
        }
    }

    function fetchTableDataFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let useData = data;
        if (!useData) {
            useData = {};
        }

        useData.page = page;
        useData.pageSize = pageSize;

        let executor;
        if (method === "get") {
            // TODO Query params
            executor = Axios.get(url);
        } else {
            executor = Axios.post(url, useData);
        }

        executor
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setTableData(resp.data.data);
                    setTotalCount(resp.data.count);
                } else {
                    handleCallback(AutoTableActions.ERROR, API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                handleCallback(AutoTableActions.ERROR, null);
            });
    }

    let paginator = [];
    if (pageSize && totalCount > pageSize) {
        paginator = (
            <div className={"ep-table-paginate-container mt-1"}>
                <div className={"push"} />
                <TablePaginator
                    page={page}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    onClick={(page) => handleCallback(AutoTableActions.PAGE_CHANGED, page)} />
            </div>
        )
    }

    return (
        <>
            <div className={"table-container"}>
                <TableComponent
                    className={"clickable"}
                    data={tableData}
                    placeholderMode={networkInFlight}
                    placeholderRows={pageSize}
                    onRowClick={(row) => handleCallback(AutoTableActions.ROW_CLICKED, row)}>

                    {props.children}

                </TableComponent>
            </div>

            {paginator}
        </>
    )

}