import axios from 'axios';
import Axios from 'axios';
import Rosetta from "../rosetta/Rosetta";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            } else if (token === null) {
                delete axios.defaults.headers.common["Authorization"];
            }
        },
        setAuthFailureCallback : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    } else {
                        return status;
                    }
                }
            }
        },
        post : (url, data) => new Promise((resolve) => {
            Axios.post(url, data)
                .then((r) => {
                    const resp = API.parse(r);
                    resolve(resp);
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        }),
        get : (url) => new Promise((resolve) => {
            Axios.get(url)
                .then((r) => {
                    const resp = API.parse(r);
                    resolve(resp);
                })
                .catch((e) => {
                    return Promise.reject(e);
                });
        }),
        parse : (resp) => {
            let out = {
                success : false,
                data : null,
                error : {
                    desc : Rosetta.string("common.error_common_uncoded"),
                    code : -1
                }
            };

            if (resp !== null) {
                out.success = resp.data.success;
                if (out.success) {
                    if (resp.data.hasOwnProperty("data")) {
                        out.data = resp.data.data;
                    } else {
                        out.data = resp.data;
                    }
                } else {
                    if (resp.data.error !== undefined) {
                        out.error.desc = resp.data.error.desc;
                        out.error.code = resp.data.error.code;
                    }
                }
            }

            return out;
        },
        formatError : (resp) => {
            if (!resp.success) {
                return resp.error.desc + " [" + resp.error.code + "]";
            }
            return "";
        },
        objectToPostData : (object) => {
            let keys = Object.keys(object);
            let formData = new FormData();

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = object[key];
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }

                formData.append(key, value);
            }

            return formData;
        },
        defaultError : (errorCode) => {
            return Rosetta.string("common.error_common_unknown", { error_code : errorCode });
        }
    }
}) (axios);

export const ENDPOINTS = {
    auth : {
        login : "auth/login",
        requestUserPasswordReset : "auth/requestUserPasswordReset",
        submitUserPasswordReset : "auth/submitUserPasswordReset"
    },
    notifications : {
        submit : "/notification/submit",
        getNotifications : "/notifications/getNotifications"
    },
    country : {
        getCountries : "/country/getCountries"
    },
    language : {
        getLanguages : "/language/getLanguages"
    },
    users : {
        getUsers : "/user/getUsers",
        submitUser : "/user/submitUser"
    }
};
