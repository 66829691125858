import {NavLink, useParams} from "react-router-dom";
import {useState} from "react";

import logoImage from "../../../assets/images/logo.png";
import {ImageUtil} from "../../../util/ImageUtil";
import Rosetta from "../../../rosetta/Rosetta";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {AlertModal} from "../../alertmodal/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Navigator} from "../../../util/Navigator";

import "./PasswordResetScreen.css";

export const PasswordResetScreen = (props) => {

    const {token} = useParams();

    const [password, setPassword] = useState("");
    const [passwordRetype, setPasswordRetype] = useState("");

    const [networkInFlight, setNetworkInFlight] = useState();

    const [failure, setFailure] = useState(false);
    const [success, setSuccess] = useState(false);

    // Network

    function submitOverNetwork() {
        if (networkInFlight) return;

        if (password.trim() === "") {
            AlertModal.showError(Rosetta.string("validation.missing_property", { label : Rosetta.string("forgotten_password.password") }));
            return;
        }

        if (password !== passwordRetype) {
            AlertModal.showError(Rosetta.string("users.editor_error_password_mismatch"));
            return;
        }

        setNetworkInFlight(true);

        let data = {
            token,
            password
        };

        Axios.post(ENDPOINTS.auth.submitUserPasswordReset, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    AlertModal.showModal(
                        Rosetta.string("common.success"),
                        resp.data.message,
                        [
                            AlertModal.button(
                                Rosetta.string("common.ok"),
                                () => {
                                    Navigator.navigate("/")
                                    AlertModal.dismissModal();
                                }
                            )
                        ]
                    )
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("FP1000C"));
            })
    }

    // Render

    let primaryContent = (
        <div className={"row mt-4"}>
            <div className={"col-12 text-center"}>
                <LoadingSpinner />
            </div>
        </div>
    );
    if (!networkInFlight) {
        if (failure) {
            primaryContent = (
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <h4>{Rosetta.string("forgotten_password.failure")}</h4>
                    </div>
                    <div className={"col-12 text-center mt-2"}>
                        <NavLink to={"/"}>{Rosetta.string("login.login")}</NavLink>
                    </div>
                </div>
            )
        } else if (success) {
            primaryContent = (
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <h4>{Rosetta.string("forgotten_password.success")}</h4>
                    </div>
                    <div className={"col-12 text-center mt-2"}>
                        <NavLink to={"/"}>{Rosetta.string("login.login")}</NavLink>
                    </div>
                </div>
            )
        } else {
            primaryContent = (
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-md-6 col-lg-4"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12 text-center"}>
                                        {Rosetta.string("forgotten_password.explain")}
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("forgotten_password.password")}</label>
                                        <input type={"password"} className={"form-control"} value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("forgotten_password.password_retype")}</label>
                                        <input type={"password"} className={"form-control"} value={passwordRetype} onChange={(e) => setPasswordRetype(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col-12 text-center"}>
                                        <button className={"btn btn-success"} onClick={() => submitOverNetwork()}>{Rosetta.string("common.submit")}</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }

    return (
        <div className={"container app-screen password-reset-screen"}>
            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-4 col-lg-3"}>
                    <div className={"ratio ratio-16x9 logo"} style={{backgroundImage : ImageUtil.background(logoImage)}} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <h3>{Rosetta.string("forgotten_password.title")}</h3>
                </div>
            </div>

            {primaryContent}

        </div>
    )

}