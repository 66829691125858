import React, {useEffect} from 'react';

import './AlertModal.css';
import Rosetta from "../../rosetta/Rosetta";
import WindowUtil from "../../util/WindowUtil";
import {BaseModal, BaseModalActions} from "./BaseModal";

export const AlertModal = {
    showModal : (title, message, buttons, icon) => {
        window._showModal(title, message, buttons, icon);
    },
    showError : (message) => {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    },
    dismissModal : () => {
        window._dismissModal();
    },
    button : (label, callback, className) => {
        return {
            label,
            click : callback,
            className
        };
    }
}

export const AlertModalComponent = (props) => {

    const {shown} = props;
    const {forceDismiss} = props;

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
        }
    }, [shown]);

    function handleCallback(action) {
        if (props.callback) {
            props.callback(action);
        }
    }

    // RENDER

    if (!shown) {
        return [];
    }

    let icon = [];
    if (props.icon !== undefined) {
        icon = (
            <div className={"alert-modal-dialog-icon"} style={{backgroundImage : "url(" + this.props.icon + ")"}} />
        );
    }

    let title = [];
    if (props.title !== undefined) {
        title = (
            <div className={"alert-modal-dialog-title"}>{props.title}</div>
        );
    }

    let message = "";
    if (props.message !== undefined) {
        message = props.message;
    }

    let buttons = [
        {
            label : Rosetta.string("common.ok"),
            click : () => {
                window._dismissModal();
            }
        }
    ];
    if (props.buttons !== undefined) {
        buttons = props.buttons;
    }

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            {icon}
            {title}
            <div className={"alert-modal-dialog-message"}>{message}</div>
            <div className={"alert-modal-dialog-buttons"}>
                {buttons.map((button, index) => {
                    let className = "alert-dialog-button";
                    if (button.hasOwnProperty("className")) {
                        className += " " + button.className;
                    }

                    return (
                        <div className={className} onClick={button.click}>{button.label}</div>
                    );
                })}
            </div>

        </BaseModal>
    );

}
