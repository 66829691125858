import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {ContentOrPlaceholder} from "../../placeholder/ContentOrPlaceholder";
import {useEffect, useState} from "react";
import {Chronos} from "../../../chronos/Chronos";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";

import "./NotificationDetailScreen.css";

export const NotificationDetailScreen = (props) => {

    const {identifier} = useParams();

    const [notification, setNotification] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [countryData, setCountryData] = useState([]);
    const [countryNetworkInFlight, setCountryNetworkInFlight] = useState(false);

    const [languageData, setLanguageData] = useState([]);
    const [languageNetworkInFlight, setLanguageNetworkInFlight] = useState(false);

    useEffect(() => {
        if (identifier) {
            fetchNotificationFromNetwork();
        }
    }, []);

    function fetchNotificationFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let formData = {
            identifiers: [identifier],
            page : 1,
            pageSize : 1,
            activeOnly : false
        };

        Axios.post(ENDPOINTS.notifications.getNotifications, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        let data = resp.data.data[0];
                        if (data) {
                            if (data.localisations !== null) {
                                data.localisations = JSON.parse(data.localisations);
                            }

                            if (data.metadata !== null) {
                                data.metadata = JSON.parse(data.metadata);
                            }
                        }
                        setNotification(data);

                        fetchCountriesFromNetwork(data);
                        fetchLanguagesFromNetwork(data);
                    } else {
                        console.log("Uhhh...");
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("ND1000C"));
            });
    }

    function fetchCountriesFromNetwork(notification) {
        if (!notification) return;
        if (notification.metadata == null) return;
        if (!notification.metadata.countries) return;

        if (countryNetworkInFlight) return;
        setCountryNetworkInFlight(true);

        let data = {
            codes : notification.metadata.countries
        };

        Axios.post(ENDPOINTS.country.getCountries, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setCountryData(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setCountryNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                // Error?
            });
    }

    function fetchLanguagesFromNetwork(notification) {
        if (!notification || notification.localisations === null || !notification.localisations) return;
        if (networkInFlight) return;

        let ids = [];
        notification.localisations.forEach((localisation) => {
            if (localisation) {
                ids.push(localisation.locale);
            }
        });

        let data = {
            ids,
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.language.getLanguages, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setLanguageData(resp.data.data);
                } else {
                    console.log(API.formatError(resp));
                }
                setLanguageNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setLanguageNetworkInFlight(false);
            });
    }

    // Render

    let metadataElem = [];
    if (notification && notification.metadata) {
        console.log(notification.metadata);

        let countries = null;
        if (notification.metadata.hasOwnProperty("countries") && notification.metadata.countries != null) {
            for (let i = 0; i < notification.metadata.countries.length; i++) {
                let countryCode = notification.metadata.countries[i];
                for (let x = 0; x < countryData.length; x++) {
                    if (countryData[x].code === countryCode) {
                        if (countries === null) {
                            countries = [];
                        }
                        countries.push(
                            <li className={"list-group-item"}>{countryData[x].name}</li>
                        )
                    }
                }
            }

            if (countries != null && countries.length > 0) {
                countries = (
                    <div className={"col-12 col-md-3 mt-2"}>
                        <label>{Rosetta.string("notifications.detail_notifications_audience_countries")}</label>
                        <ul className={"list-group"}>
                            {countries}
                        </ul>
                    </div>

                );
            }
        }

        let imeis = null;
        if (notification.metadata.hasOwnProperty("imeis")) {
            if (notification.metadata.imeis !== null && notification.metadata.imeis.length > 0) {
                imeis = [];

                for (let i = 0; i < notification.metadata.imeis.length; i++) {
                    imeis.push(
                        <li className={"list-group-item"}>{notification.metadata.imeis[i]}</li>
                    )
                }

                imeis = (
                    <div className={"col-12 col-md-3 mt-2"}>
                        <label>{Rosetta.string("notifications.detail_notifications_audience_imeis")}</label>
                        <ul className={"list-group"}>
                            {imeis}
                        </ul>
                    </div>
                );
            }
        }

        let deviceSerials = null;
        if (notification.metadata.hasOwnProperty("deviceSerials")) {
            if (notification.metadata.deviceSerials !== null && notification.metadata.deviceSerials.length > 0) {
                deviceSerials = [];

                for (let i = 0; i < notification.metadata.deviceSerials.length; i++) {
                    deviceSerials.push(
                        <li className={"list-group-item"}>{notification.metadata.deviceSerials[i]}</li>
                    )
                }

                deviceSerials = (
                    <div className={"col-12 col-md-3 mt-2"}>
                        <label>{Rosetta.string("notifications.detail_notifications_audience_serials")}</label>
                        <ul className={"list-group"}>
                            {deviceSerials}
                        </ul>
                    </div>

                );
            }
        }

        let deviceModels = null;
        if (notification.metadata.hasOwnProperty("deviceModels")) {
            if (notification.metadata.deviceModels !== null && notification.metadata.deviceModels.length > 0) {
                deviceModels = [];

                for (let i = 0; i < notification.metadata.deviceModels.length; i++) {
                    deviceModels.push(
                        <li className={"list-group-item"}>{notification.metadata.deviceModels[i]}</li>
                    )
                }

                deviceModels = (
                    <div className={"col-12 col-md-3 mt-2"}>
                        <label>{Rosetta.string("notifications.detail_notifications_audience_models")}</label>
                        <ul className={"list-group"}>
                            {deviceModels}
                        </ul>
                    </div>
                );
            }
        }

        if (countries !== null || imeis !== null || deviceSerials !== null || deviceModels !== null) {
            metadataElem = (
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-md-10 text-center"}>
                        <h3>{Rosetta.string("notifications.detail_notifications_audience")}</h3>
                    </div>

                    {countries}
                    {imeis}
                    {deviceSerials}
                    {deviceModels}
                </div>
            )
        }
    }

    let localisationElems = [];
    if (languageData && notification && notification.localisations != null && notification.localisations.length > 0) {
        console.log(notification.localisations);
        notification.localisations.forEach((localisation) => {
            if (localisation) {
                let languageName = localisation.locale;
                for (let i = 0; i < languageData.length; i++) {
                    if (languageData[i].id === localisation.locale) {
                        languageName = languageData[i].name;
                        break;
                    }
                }

                localisationElems.push(
                    <div className={"col-12 col-md-4 col-lg-3 mt-2"}>
                        <div className={"card"}>
                            <div className={"card-header"}>{languageName}</div>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("notifications.detail_notification_title")}</label>
                                        <div className={"form-control"}>{localisation.data.title}</div>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("notifications.detail_notification_message")}</label>
                                        <div className={"form-control"}>{localisation.data.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        });

        if (localisationElems.length > 0) {
            localisationElems = (
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 text-center"}>
                        <h3>{Rosetta.string("notifications.detail_notifications_localisations")}</h3>
                    </div>
                    {localisationElems}
                </div>
            )
        }
    }

    return (
        <div className={"app-screen notification-detail-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("notifications.detail_title")} />
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.detail_notification_title")}</label>
                                    <ContentOrPlaceholder showContent={!networkInFlight}>
                                        <div className={"form-control"}>{notification ? notification.title : "&nbsp;" }</div>
                                    </ContentOrPlaceholder>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.detail_notification_message")}</label>
                                    <ContentOrPlaceholder showContent={!networkInFlight}>
                                        <div className={"form-control"}>{notification ? notification.message : "&nbsp;" }</div>
                                    </ContentOrPlaceholder>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.detail_notification_url")}</label>
                                    <ContentOrPlaceholder showContent={!networkInFlight}>
                                        <div className={"form-control"}><a href={notification ? notification.url : "#"}>{notification ? notification.url : "&nbsp;" }</a></div>
                                    </ContentOrPlaceholder>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.detail_notification_date")}</label>
                                    <ContentOrPlaceholder showContent={!networkInFlight}>
                                        <div className={"form-control"}>{notification ? Chronos.withTimestampSeconds(notification.dateCreated).format("dd/MM/yyyy HH:mm") : "&nbsp;" }</div>
                                    </ContentOrPlaceholder>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-lg-10"}>

                    <div className={"row"}>
                        <div className={"col-12 text-center"}>
                            <h3>{Rosetta.string("notifications.detail_notification_statistics")}</h3>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row mt-4 justify-content-center"}>
                                        <div className={"col-12"}>
                                            <div className={"progress"}>
                                                <div className={"progress-bar"} style={{ width : (notification ? ((notification.interactionCount / notification.sendCount) * 100) + "%" : "0%")}}></div>
                                            </div>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4 text-center mt-2"}>
                                            <div className={"statistics-title"}>{Rosetta.string("notifications.detail_notification_recipients")}</div>
                                            <div className={"statistics-value"}>{ notification ? notification.sendCount : "0" }</div>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4 text-center mt-2"}>
                                            <div className={"statistics-title"}>{Rosetta.string("notifications.detail_notification_interactions")}</div>
                                            <div className={"statistics-value"}>{ notification ? notification.interactionCount : "0" }</div>
                                        </div>

                                        <div className={"col-12 col-md-6 col-lg-4 text-center mt-2"}>
                                            <div className={"statistics-title"}>{Rosetta.string("notifications.detail_notifications_conversion")}</div>
                                            <div className={"statistics-value"}>{ notification ? ((notification.interactionCount / notification.sendCount) * 100).toFixed(0) + "%" : "0%" }</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {metadataElem}

            {localisationElems}

        </div>
    )

}