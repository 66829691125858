import "./ScreenTitle.css";

export const ScreenTitle = (props) => {

    let screenTitle = "";
    if (props.title !== undefined) {
        screenTitle = props.title;
    }

    let titleElems = [];
    for (let i = 0; i < screenTitle.length; i++) {
        let letter = screenTitle[i];
        if (letter === " ") {
            letter = (<>&nbsp;</>);
        }
        titleElems.push(
            <span className={"let"}>{letter}</span>
        )
    }

    return (
        <div className={"screen-title"}>
            {titleElems}
        </div>
    )

}