import {ImageUtil} from "../../util/ImageUtil";

import "./LoadingSpinner.css";
import AfterImageLoadingSpinner from "./afterimage/AfterImageLoadingSpinner";

export const LoadingSpinner = (props) => {

    let classExtra = "";
    if (props.inline && props.inline === true) {
        classExtra = " inline";
    }

    if (props.small && props.small === true) {
        classExtra += " small";
    }

    return (
        <AfterImageLoadingSpinner />
    )

}