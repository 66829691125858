import React from 'react';

import "./AfterImageLoadingSpinner.css";

export default class AfterImageLoadingSpinner extends React.Component {

    render() {
        let shown = this.props.shown;
        if (shown === undefined) {
            shown = true;
        }
        if (!shown) return [];

        let extraClass = "";
        if (this.props.inverted !== undefined) {
            if (this.props.inverted) {
                extraClass = " inverted";
            }
        }

        return (
            <div className={"loading-spinner" + extraClass}>
                <div className={"loading-box hero-box"} />
                <div className={"loading-box static-box-one"} />
                <div className={"loading-box static-box-two"} />
                <div className={"loading-box static-box-three"} />
            </div>
        )
    }

}