import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {AutoTable, AutoTableActions} from "../../table/AutoTable";
import {useEffect, useRef, useState} from "react";
import {AlertModal} from "../../alertmodal/AlertModal";
import {Column} from "../../table/TableComponent";
import {API, ENDPOINTS} from "../../../network/API";
import {Chronos} from "../../../chronos/Chronos";
import {StringUtil} from "../../../util/StringUtil";
import {FilterText} from "../../filter/FilterText";
import {CommonUtil} from "../../../util/CommonUtil";
import {Navigator} from "../../../util/Navigator";

export const NotificationListScreen = (props) => {

    const [page, setPage] = useState(1);
    const [tableNetworkInFlight, setTableNetworkInFlight] = useState(false);

    const [keyword, setKeyword] = useState("");

    const [tableData, setTableData] = useState({});

    useEffect(() => {
        let data = CommonUtil.cloneObject(tableData);
        if (keyword !== "") {
            data.keyword = keyword;
        } else {
            delete data.keyword;
        }
        setTableData(data);
    }, [keyword]);

    function moveToEditor() {
        Navigator.navigate("/notification/create");
    }

    function moveToDetail(identifier) {
        Navigator.navigate("/notification/detail/" + identifier);
    }

    function tableDidCallback(action, data) {
        console.log("CALLBACK: " + action + " data: " + data);
        if (action === AutoTableActions.NETWORK_IN_FLIGHT) {
            setTableNetworkInFlight(data);
        } else if (action === AutoTableActions.PAGE_CHANGED) {
            setPage(data);
        } else if (action === AutoTableActions.ERROR) {
            let message = data;
            if (!message) {
                message = API.defaultError("NL1000C");
            }
            AlertModal.showError(message);
        } else if (action === AutoTableActions.ROW_CLICKED) {
            moveToDetail(data.identifier);
        }
    }

    return (
        <div className={"app-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("notifications.title")} />
                </div>

                <div className={"col-12 col-md-4 screen-actions"}>
                    <button className={"btn btn-primary"} onClick={() => moveToEditor()}>{Rosetta.string("notifications.action_new")}</button>
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-10"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                            <FilterText callback={setKeyword} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-10"}>
                    <AutoTable
                        url={ENDPOINTS.notifications.getNotifications}
                        data={tableData}
                        page={page}
                        pageSize={20}
                        callback={tableDidCallback}>

                        <Column name={"dateCreated"} title={Rosetta.string("notifications.table_date")} render={(data, row) => {
                            return Chronos.withTimestampSeconds(data).format("dd/MM/yy HH:mm");
                        }} />
                        <Column name={"title"} title={Rosetta.string("notifications.table_title")} />
                        <Column name={"url"} title={Rosetta.string("notifications.table_url")} render={(data, row) => {
                            return StringUtil.truncateCentre(data, 32);
                        }} />
                        <Column name={"sendCount"} className={"text-center"} title={Rosetta.string("notifications.table_recipients")} />
                        <Column name={"interactionCount"} className={"text-center"} title={Rosetta.string("notifications.table_interactions")} />
                        <Column name={"id"} className={"text-center"} title={Rosetta.string("notifications.table_rate")} render={(data, row) => {
                            return (<span>{Math.round((row.interactionCount / row.sendCount) * 100).toFixed(0)}%</span>)
                        }} />

                    </AutoTable>
                </div>
            </div>
        </div>
    )

}