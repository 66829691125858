import "./ToastContainerComponent.css";

import {useEffect, useState} from "react";

import icon_success from "./icon_success.svg";
import icon_warning from "./icon_warning.svg";
import icon_info from "./icon_help.svg"
import icon_close from "./icon_close.svg";

export const TokyoToaster = (props) => {

    const [toasts, setToasts] = useState([]);
    const [dismissKey, setDismissKey] = useState(undefined);
    const [deleteKey, setDeleteKey] = useState(undefined);

    useEffect(() => {
        setupListener();

        return () => {
            window._epToast = undefined;
            window._epToastDismiss = undefined;
        }
    }, []);

    useEffect(() => {
        setupListener();
    }, [toasts])

    useEffect(() => {
        beginToastDismiss(dismissKey);
    }, [dismissKey]);

    useEffect(() => {
        removeToast(deleteKey);
    }, [deleteKey]);

    function setupListener() {
        window._epToast = (title, message, type, duration, actions) => {
            let t = [...toasts];

            let key = Math.random();

            let timeout = undefined;
            if (duration > 0) {
                timeout = setTimeout(() => {
                    setDismissKey(key);
                }, duration);
            }

            if (actions !== undefined) {
                if (!Array.isArray(actions)) {
                    actions = [actions];
                }
            }

            t.push({
                key,
                title,
                message,
                type,
                duration,
                actions,
                timeout,
                dismissing: false
            });
            setToasts(t);
        };

        window._epToastDismiss = (key) => {
            beginToastDismiss(key);
        }
    }

    function beginToastDismiss(key) {
        let t = [...toasts];

        for (let i = 0; i < t.length; i++) {
            if (t[i].key === key) {
                t[i].dismissing = true;
                break;
            }
        }

        setTimeout(() => {
            setDeleteKey(key);
        }, 1200);

        setToasts(t);
    }

    function removeToast(key) {
        let t = [...toasts];

        for (let i = 0; i < t.length; i++) {
            if (t[i].key === key) {
                t.splice(i,1);
                break;
            }
        }
        setToasts(t);
    }

    let toastElems = [];
    toasts.forEach((toast) => {
        let key = toast.key;
        let className = "info";
        let icon = icon_warning;

        switch (toast.type) {
            case Toast.SUCCESS:
                className = "success";
                icon = icon_success;
                break;
            case Toast.ERROR:
                className = "danger";
                break;
            case Toast.WARNING:
                className = "warning";
                break;
            case Toast.INFO:
                icon = icon_info;
                break;
        }

        if (toast.dismissing) {
            key = "d_" + key;
            className += " dismissing";
        }

        let actionsElem = [];
        if (toast.actions !== undefined && toast.actions.length > 0) {
            actionsElem = (
                <span className={"actions"}>
                    {
                        toast.actions.map((action) => (
                            <span className={"action-item"} onClick={() => action.onClick(key)}>{action.label}</span>
                        ))
                    }
                </span>
            )
        }

        toastElems.push(
            <div className={"toast-item " + className} key={key}>
                <div className={"toast-icon"} style={{backgroundImage : "url(" + icon + ")"}} />
                <div className={"toast-content"}>
                    <div className={"toast-underlay"}>
                        <span className={"spacer"} />
                        <span>{toast.title}</span>
                        <span className={"spacer"} />
                    </div>
                    <div className={"toast-overlay"}>
                        <span className={"title"}>{toast.title}</span>
                        <span className={"body"}>{toast.message}</span>
                        {actionsElem}
                    </div>
                </div>
                <div className={"toast-close"} style={{backgroundImage : "url(" + icon_close + ")"}} onClick={() => beginToastDismiss(key)} />
            </div>
        );
    });

    return (
        <>
            <div className={"toast-container-component"}>
                {toastElems}
            </div>
            {props.children}
        </>

    )

}

export const Toast = {
    SUCCESS : "success",
    WARNING : "warning",
    INFO : "info",
    ERROR : "error",
    INFINITE : -1,
    SHORT : 4800,
    LONG : 6000,
    show : (title, message, type, duration, actions) => {
        window._epToast(title, message, type, duration, actions);
        return true;
    },
    action : (label, onClick) => {
        return { label,  onClick}
    },
    dismiss : (key) => {
        window._epToastDismiss(key);
        return true;
    }
}