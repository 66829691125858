import React from 'react';

import './TableComponent.css';

import sortAsc from "./sort_asc.png";
import sortDesc from "./sort_desc.png";
import sortNone from "./sort_both.png";
import {PlaceholderText} from "../placeholder/PlaceholderText";

class TableComponent extends React.Component {

    SORT_DIRECTION_NONE = "none";
    SORT_DIRECTION_ASCENDING = "asc";
    SORT_DIRECTION_DESCENDING = "desc";

    constructor(props, context) {
        super(props, context);

        var columns = [];

        this.state = {
            columns
        };
    }

    componentDidMount() {
        this.processColumns();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.children !== this.props.children) {
            this.processColumns();
        }
    }

    processColumns = () => {
        let columns = [];


        if (this.props.children != null) {
            React.Children.map(this.props.children, (child) => {
                if (child.type === Column) {
                    let columnDef = {
                        name : child.props.name,
                        title : child.props.title,
                        className : child.props.className
                    };

                    if (child.props.columnClassName !== undefined) {
                        columnDef.columnClassName = child.props.columnClassName;
                    }

                    if (child.props.render != null) {
                        columnDef.render = child.props.render;
                    }

                    if (child.props.hasOwnProperty("sortable")) {
                        columnDef.sortable = child.props.sortable;
                    }

                    if (child.props.customFormat !== undefined) {
                        columnDef.customFormat = child.props.customFormat;
                    }

                    columns.push(columnDef);
                }
            });
        } else if (this.props.columns != null) {
            columns = this.props.columns;
        }

        this.setState({
            columns : columns
        });
    }

    handleClick(row) {
        if (this.props.onRowClick != null) {
            this.props.onRowClick(row);
        }
    }

    handleHeadingClick = (column) => {
        if (this.props.onSort != null) {
            if (column != null) {
                if (column.sortable) {
                    let currentSort = this.SORT_DIRECTION_NONE;

                    let sortedColumn = null;
                    if (this.props.hasOwnProperty("sortedColumn")) {
                        sortedColumn = this.props.sortedColumn;
                    }

                    if (sortedColumn != null) {
                        if (sortedColumn.name === column.name) {
                            currentSort = sortedColumn.direction;
                        }
                    }

                    if (currentSort === this.SORT_DIRECTION_ASCENDING) {
                        currentSort = this.SORT_DIRECTION_DESCENDING;
                    } else {
                        currentSort = this.SORT_DIRECTION_ASCENDING;
                    }

                    this.props.onSort({
                        name : column.name,
                        direction : currentSort
                    });
                }
            }
        }
    }

    createColumnElem = (column, data, content, callback) => {
        if (column === undefined) return [];

        let colData = null;

        if (data !== null) {
            if (data.hasOwnProperty(column.name)) {
                colData = data[column.name];
            }
        }

        let classNameExtra = "";
        if (column.hasOwnProperty("columnClassName")) {
            classNameExtra += column.columnClassName;
        }

        if (column.customFormat !== undefined) {
            classNameExtra = column.customFormat(colData, data, content, column);
        }
        if (classNameExtra !== "") {
            classNameExtra = " " + classNameExtra;
        }

        return(
            <td key={"td_" + column.name}
                className={column.className + classNameExtra}
                onClick={callback}>
                {content}
            </td>
        );
    }

    render() {
        let placeholderMode = false;
        let placeholderRows = 10;
        if (this.props.placeholderMode !== undefined) {
            placeholderMode = this.props.placeholderMode;
        }

        if (this.props.placeholderRows !== undefined) {
            placeholderRows = this.props.placeholderRows;
        }

        var headerColumns = [];
        var tableRows = [];

        let sortedColumn = null;
        if (this.props.hasOwnProperty("sortedColumn")) {
            sortedColumn = this.props.sortedColumn;
        }

        let showHeader = true;
        if (this.props.showHeader !== undefined) {
            showHeader = this.props.showHeader;
        }

        if (this.state.columns != null) {
            if (showHeader) {
                for (let i = 0; i < this.state.columns.length; i++) {
                    let column = this.state.columns[i];

                    let sortable = false;
                    if (column.hasOwnProperty("sortable")) {
                        sortable = column.sortable === true;
                    }

                    let sortDirection = this.SORT_DIRECTION_NONE;
                    if (sortedColumn != null) {
                        if (sortedColumn.name === column.name) {
                            sortDirection = sortedColumn.direction;
                        }
                    }

                    let columnHeaderClass = column.className;
                    let columnHeaderStyle = {};
                    if (sortable) {
                        if (columnHeaderClass === undefined || columnHeaderClass == null) {
                            columnHeaderClass = "";
                        } else {
                            columnHeaderClass += " ";
                        }

                        columnHeaderClass = "sortable";

                        if (sortDirection === this.SORT_DIRECTION_ASCENDING) {
                            columnHeaderStyle.backgroundImage = "url(" + sortAsc + ")";
                        } else if (sortDirection === this.SORT_DIRECTION_DESCENDING) {
                            columnHeaderStyle.backgroundImage = "url(" + sortDesc + ")";
                        } else {
                            columnHeaderStyle.backgroundImage = "url(" + sortNone + ")";
                        }
                    }

                    headerColumns.push(
                        <th key={"th_" + i} className={columnHeaderClass} style={columnHeaderStyle}
                            onClick={() => this.handleHeadingClick(column)}>
                            {column.title}
                        </th>
                    );
                }
            }

            if (!placeholderMode) {
                if (this.props.data != null) {
                    for (let i = 0; i < this.props.data.length; i++) {
                        let columns = [];

                        let data = this.props.data[i];

                        for (let x = 0; x < this.state.columns.length; x++) {
                            let column = this.state.columns[x];
                            let colValue = "";
                            let colData = null;

                            if (data.hasOwnProperty(column.name)) {
                                colData = data[column.name];
                                if (column.hasOwnProperty("render")) {
                                    colValue = column.render(colData, data)
                                } else {
                                    colValue = colData;
                                }
                            }

                            let clickListener = undefined;
                            if (column.onClick !== undefined) {
                                clickListener = column.onClick;
                            }

                            columns.push(this.createColumnElem(column, data, colValue, (event) => (clickListener !== undefined && clickListener !== null) ? clickListener(event, data, colData) : undefined));
                        }

                        tableRows.push(
                            <tr key={i} onClick={() => this.handleClick(data)}>
                                {columns}
                            </tr>
                        );
                    }
                }
            } else {
                for (let i = 0; i < placeholderRows; i++) {
                    let columns = [];
                    for (let x = 0; x < this.state.columns.length; x++) {
                        columns.push(this.createColumnElem(this.state.columns[x], null, (<PlaceholderText />)));
                    }
                    tableRows.push(<tr key={"placeholder_" + i}>{columns}</tr>);
                }
            }
        }

        if (tableRows.length === 0) {
            var emptyClassName = "empty-message";
            if (this.props.emptyClassName != null) {
                emptyClassName = this.props.emptyClassName;
            }

            var emptyMessage = "No data to display";
            if (this.props.emptyMessage != null) {
                emptyMessage = this.props.emptyMessage;
            }

            tableRows.push(<tr>
                <td colSpan={headerColumns.length} className={emptyClassName}>{emptyMessage}</td>
            </tr>);
        }

        let tableClassName = "";
        if (this.props.className !== undefined && this.props.className != null) {
            tableClassName += " " + this.props.className;
        }

        let headerRow = [];
        if (showHeader) {
            headerRow = (
                <thead>
                <tr>
                    { headerColumns }
                </tr>
                </thead>
            );
        }

        return (
            <table className={tableClassName}>
                {headerRow}
                <tbody>
                { tableRows }
                </tbody>
            </table>
        );
    }

}

export default TableComponent;

class Column extends React.Component {}

class TablePaginator extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.incrementPage = this.incrementPage.bind(this);
        this.decrementPage = this.decrementPage.bind(this);
    }

    handleClick(index) {
        if (this.props.onClick != null) {
            this.props.onClick(index);
        }
    };

    incrementPage() {
        let totalCount = 0;
        if (this.props.totalCount != null) {
            totalCount = Math.max(0, this.props.totalCount);
        }

        let pageSize = 10;
        if (this.props.pageSize != null) {
            pageSize = Math.max(1, this.props.pageSize);
        }

        let maxPages = parseInt(Math.ceil(totalCount / pageSize));
        let nextPage = parseInt(Math.min(maxPages, this.props.page + 1));

        this.handleClick(nextPage);
    }

    decrementPage() {
        let nextPage = Math.max(1, this.props.page - 1);
        this.handleClick(nextPage);
    }

    render() {
        let totalCount = 0;
        if (this.props.totalCount != null) {
            totalCount = Math.max(0, this.props.totalCount);
        }

        let pageSize = 10;
        if (this.props.pageSize != null) {
            pageSize = Math.max(1, this.props.pageSize);
        }

        let page = 1;
        if (this.props.page != null) {
            page = Math.max(1, this.props.page);
        }

        let maxItems = 10;
        if (this.props.maxItems != null) {
            maxItems = this.props.maxItems;
        }

        let items = [];

        items.push(<span className={"page-prev"} onClick={this.decrementPage}>&#9664;</span>);

        let totalPages = parseInt(Math.ceil(totalCount / pageSize));
        totalPages = Math.max(totalPages, 1);
        if (totalPages > maxItems) {
            let halfPage = maxItems / 2;

            let insertPosition = false;
            let countTo = halfPage;
            let backendCount = (totalPages - (halfPage - 1));
            if (page > halfPage && (page <= backendCount)) {
                halfPage--;
                insertPosition = true;
            }

            for (let i = 0; i < halfPage; i++) {
                let extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }

            if (insertPosition) {
                items.push(<span className={"page-gap"}>&#8230;</span>);
                items.push(<span className={"page-item active"} onClick={() => this.handleClick(page)}>{page}</span> );
            }
            items.push(<span className={"page-gap"}>&#8230;</span>);

            for (let i = backendCount; i < totalPages; i++) {
                let extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }
        } else {
            for (let i = 0; i < totalPages; i++) {
                let extraClass = "";
                if (page === (i + 1)) {
                    extraClass = " active";
                }
                items.push(<span className={"page-item" + extraClass} onClick={() => this.handleClick((i + 1))}>{(i + 1)}</span>);
            }
        }

        items.push(<span className={"page-next"} onClick={this.incrementPage}>&#9658;</span>);

       return (
            <span className={"ep-table-paginate"}>
                {items}
            </span>
        );
    };

}

export { Column, TablePaginator };