import {BaseModal, BaseModalActions} from "../../alertmodal/BaseModal";
import {useEffect, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import WindowUtil from "../../../util/WindowUtil";

import "./NotificationPreviewModal.css";

export const NotificationPreviewModal = (props) => {

    const {shown} = props;
    const {notification} = props;
    const {summary} = props;

    const [forceDismiss, setForceDismiss] = useState(false);
    const [callbackData, setCallbackData] = useState(undefined);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
        } else {
            setForceDismiss(false);
            setCallbackData(undefined);

            WindowUtil.unlockBodyScroll();
        }
    }, [shown]);

    function handleCallback(action) {
        if (props.callback) {
            props.callback(action, callbackData);
        }
    }

    function submitNotification() {
        setCallbackData(true);
        setForceDismiss(true);
    }

    if (!shown) return [];

    return (
        <BaseModal
            shown={true}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"notification-preview-modal"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"alert-modal-dialog-title"}>{Rosetta.string("notifications.editor_preview_title")}</div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>

                        <div className={"notification-preview"}>
                            <div className={"top-line"}>
                                <div className={"notification-icon"} />
                                <div className={"notification-title"}>{Rosetta.string("notifications.editor_preview_notification_title")}</div>
                            </div>
                            <div className={"title-line"}>
                                {notification ? notification.title : ""}
                            </div>
                            <div className={"message-line"}>
                                {notification ? notification.message : ""}
                            </div>
                        </div>

                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        {Rosetta.string("notifications.editor_preview_audience_title")}
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-6 text-center summary-count"}>
                        <div className={"number"}>{summary ? summary.sendCount : 0}</div>
                        <div className={"label"}>{Rosetta.string("notifications.editor_preview_audience_devices")}</div>
                    </div>

                    <div className={"col-6 text-center summary-count"}>
                        <div className={"number"}>{summary ? summary.localeCount : 0}</div>
                        <div className={"label"}>{Rosetta.string("notifications.editor_preview_audience_locales")}</div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-success"} onClick={() => submitNotification()}>{Rosetta.string("notifications.editor_preview_submit")}</button>
                        <button className={"btn btn-secondary ms-2"} onClick={() => handleCallback(BaseModalActions.CLOSE)}>{Rosetta.string("common.cancel")}</button>
                    </div>
                </div>

            </div>

        </BaseModal>
    )

}