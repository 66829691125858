import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {FilterText} from "../../filter/FilterText";
import {useEffect, useState} from "react";
import {CommonUtil} from "../../../util/CommonUtil";
import {AutoTable, AutoTableActions} from "../../table/AutoTable";
import {Column} from "../../table/TableComponent";
import {API, ENDPOINTS} from "../../../network/API";
import {AlertModal} from "../../alertmodal/AlertModal";
import {UserEditorModal} from "./UserEditorModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";

export const UserListScreen = (props) => {

    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [tableData, setTableData] = useState({});

    const [userEditorShown, setUserEditorShown] = useState(false);
    const [userEditorUserId, setUserEditorUserId] = useState(null);

    useEffect(() => {
        let data = CommonUtil.cloneObject(tableData);
        if (keyword !== "") {
            data.keyword = keyword;
        } else {
            delete data.keyword;
        }
        setTableData(data);
    }, [keyword]);

    function moveToEditor(id) {
        setUserEditorUserId(id);
        setUserEditorShown(true);
    }

    function tableDidCallback(action, data) {
        if (action === AutoTableActions.ERROR) {
            if (!data) {
                data = API.defaultError("UL1000C");
            }
            AlertModal.showError(data);
        } else if (action === AutoTableActions.ROW_CLICKED) {
            moveToEditor(data.id);
        } else if (action === AutoTableActions.PAGE_CHANGED) {
            setPage(data);
        }
    }

    function userEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                // Refresh table
                let td = CommonUtil.cloneObject(tableData);
                setTableData(td);
            }
            setUserEditorShown(false);
        }
    }

    return (
        <div className={"app-screen user-list-screen"}>
            <div className={"row"}>
                <div className={"col-12 col-md-10"}>
                    <ScreenTitle title={Rosetta.string("users.list_title")} />
                </div>
                <div className={"col-12 col-md-2 screen-actions"}>
                    <button className={"btn btn-primary"} onClick={() => moveToEditor()}>{Rosetta.string("users.list_new")}</button>
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-10"}>

                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                            <FilterText callback={setKeyword} />
                        </div>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"col-12"}>
                            <AutoTable
                                url={ENDPOINTS.users.getUsers}
                                data={tableData}
                                page={page}
                                pageSize={20}
                                callback={tableDidCallback}>

                                <Column name={"emailAddress"} title={Rosetta.string("users.table_email")} />
                                <Column name={"givenName"} title={Rosetta.string("users.table_given_name")} />
                                <Column name={"familyName"} title={Rosetta.string("users.table_family_name")} />
                                <Column name={"active"} title={Rosetta.string("users.table_active")} className={"text-center"} render={(data, row) => {
                                    let label = Rosetta.string("common.no");
                                    let className = "bg-secondary";
                                    if (parseInt(data) === 1) {
                                        label = Rosetta.string("common.yes");
                                        className = "bg-success";
                                    }

                                    return (
                                        <span className={"badge " + className}>{label}</span>
                                    );
                                }} />

                            </AutoTable>
                        </div>
                    </div>

                </div>
            </div>

            <UserEditorModal
                shown={userEditorShown}
                userId={userEditorUserId}
                callback={userEditorDidCallback} />

        </div>
    )

}