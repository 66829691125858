import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {LoadingSpinner} from "../../loading/LoadingSpinner";

export const ForgottenPasswordModal = (props) => {

    const {shown} = props;

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    function handleCallback(action, data) {
        if (props.callback) {
            props.callback(action, data);
        }
    }

    function submitForgottenPasswordOverNetwork() {
        if (networkInFlight) return;

        if (email.trim() === "") {
            setError(Rosetta.string("validation.missing_property", { label : Rosetta.string("login.username")}));
            return;
        }

        setError(null);

        setNetworkInFlight(false);

        let data = {
            emailAddress: email
        };

        Axios.post(ENDPOINTS.auth.requestUserPasswordReset, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("login.forgotten_password_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setError(API.defaultError("FP1000C"));
            });
    }

    if (!shown) return [];

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        )
    }

    let submitButton = (<button className={"btn btn-primary"} onClick={() => submitForgottenPasswordOverNetwork()}>{Rosetta.string("common.submit")}</button>);
    if (networkInFlight) {
        submitButton = (<LoadingSpinner />);
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("login.forgotten_password")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    {Rosetta.string("login.forgotten_password_message")}
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("login.username")}</label>
                    <input type={"text"} className={"form-control"} value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
            </div>

            {errorElem}

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

        </Offcanvas>
    )

}