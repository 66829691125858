import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../rosetta/Rosetta";
import {useState} from "react";
import {CountrySelectorModal} from "../countries/CountrySelectorModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {LanguageSelectorModal} from "../language/LanguageSelectorModal";
import {v4 as uuidv4} from "uuid";
import Validator from "../../../util/Validator";
import {AlertModal} from "../../alertmodal/AlertModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import {LoadingSpinner} from "../../loading/LoadingSpinner";
import {NotificationPreviewModal} from "./NotificationPreviewModal";
import {BaseModalActions} from "../../alertmodal/BaseModal";
import {Navigator} from "../../../util/Navigator";

export const NotificationEditorScreen = (props) => {

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [url, setUrl] = useState("");
    const [imeis, setImeis] = useState("");
    const [models, setModels] = useState("");
    const [serials, setSerials] = useState("");

    const [countrySelectorShown, setCountrySelectorShown] = useState(false);
    const [countries, setCountries] = useState([]);

    const [localisations, setLocalisations] = useState([]);
    const [localisationId, setLocalisationId] = useState(undefined);
    const [languageSelectorShown, setLanguageSelectorShown] = useState(false);

    const [submitPreviewShown, setSubmitPreviewShown] = useState(false);
    const [submitPreviewData, setSubmitPreviewData] = useState(false);
    const [submitPreviewNotification, setSubmitPreviewNotification] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    function countrySelectorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setCountries(data);
            }
            setCountrySelectorShown(false);
        }
    }

    function setLocalisationProperty(localId, propertyName, value) {
        let lItems = [...localisations];

        for (let i = 0; i < lItems.length; i++) {
            if (lItems[i].localId === localId) {
                lItems[i][propertyName] = value;
                break;
            }
        }

        setLocalisations(lItems);
    }

    function launchLanguageSelector(localId) {
        setLocalisationId(localId);
        setLanguageSelectorShown(true);
    }

    function languageSelectorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data && data.length > 0) {
                let lang = data[0];

                let lItems = [...localisations];

                if (localisationId) {
                    for (let i = 0; i < lItems.length; i++) {
                        if (lItems[i].localId === localisationId) {
                            lItems[i].language = lang;
                            break;
                        }
                    }
                } else {
                    lItems.push({
                        localId : uuidv4(),
                        language : lang,
                        title : "",
                        message : ""
                    });
                }

                setLocalisations(lItems);
            }

            setLanguageSelectorShown(false);
        }
    }

    function previewDidCallback(action, data) {
        if (action === BaseModalActions.CLOSE) {
            if (data) {
                // Properly submit the data now
                submitNotificationOverNetwork(false);
            }
            setSubmitPreviewShown(false);
        }
    }

    function showPreviewModal(summary) {
        setSubmitPreviewData(summary);
        setSubmitPreviewNotification({
            title,
            message,
            localisations
        });
        setSubmitPreviewShown(true);
    }

    // Network

    function submitNotificationOverNetwork(mock) {
        if (mock === undefined) {
            mock = true;
        }

        if (networkInFlight) return;

        const validationResult = Validator.validateCreateFormData({
            title, message, url
        }, [
            Validator.rule("title", "string", Rosetta.string("notifications.editor_notification_title"), "title"),
            Validator.rule("message", "string", Rosetta.string("notifications.editor_notification_message"), "message"),
            Validator.rule("url", "string", Rosetta.string("notifications.editor_notification_url"), "url")
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setNetworkInFlight(true);

        const formData = validationResult.formData;

        if (mock) {
            formData.append("mock", mock);
        }

        let imei = explodeCommaList(imeis);
        let serial = explodeCommaList(serials);
        let model = explodeCommaList(models);

        let locales = [];
        for (let i = 0; i < localisations.length; i++) {
            let locale = localisations[i];
            locales.push({
                locale : locale.language.id,
                data : {
                    title : locale.title,
                    message : locale.message
                }
            })
        }

        if (imei.length > 0) {
            formData.append("imeis", JSON.stringify(imei));
        }

        if (serial.length > 0) {
            formData.append("deviceSerials", JSON.stringify(serial));
        }

        if (model.length > 0) {
            formData.append("deviceModels", JSON.stringify(model));
        }

        if (locales.length > 0) {
            formData.append("localisations", JSON.stringify(locales));
        }

        if (countries.length > 0) {
            let countryData = [];
            for (let i = 0; i < countries.length; i++) {
                countryData.push(countries[i].code);
            }
            formData.append("countries", JSON.stringify(countryData));
        }

        Axios.post(ENDPOINTS.notifications.submit, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (!mock) {
                        Toast.show(
                            Rosetta.string("common.success"),
                            Rosetta.string("notifications.editor_submit_success"),
                            Toast.SUCCESS,
                            Toast.LONG
                        );

                        if (resp.data.identifier !== undefined) {
                            Navigator.navigate("/notification/detail/" + resp.data.identifier);
                        }
                    } else {
                        showPreviewModal(resp.data);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("NS1000C"));
            });
    }

    function explodeCommaList(input) {
        let out = [];
        if (input.length > 0) {
            let exploded = input.split(",");
            for (let i = 0; i < exploded.length; i++) {
                out.push(exploded[i].trim());
            }
        }
        return out;
    }

    let localisationElems = localisations.map((localisation) => (
        <div className={"row mt-2"}>
            <div className={"col-12"}>
                <div className={"card"}>
                    <div className={"card-body"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("notifications.editor_localisations_language")}</label>
                                <div className={"form-control clickable"} onClick={() => launchLanguageSelector(localisation.localId)}>{localisation.language.name}</div>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("notifications.editor_localisations_title")}</label>
                                <input type={"text"} className={"form-control"} value={localisation.title} onChange={(e) => setLocalisationProperty(localisation.localId, "title", e.target.value)} />
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("notifications.editor_localisations_title")}</label>
                                <textarea className={"form-control"} value={localisation.message} onChange={(e) => setLocalisationProperty(localisation.localId, "message", e.target.value)} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    ));

    if (localisationElems.length === 0) {
        localisationElems = (
            <div className={"card"}>
                <div className={"card-body"}>
                    <div className={"row"}>
                        <div className={"col-12 text-center"}>
                            {Rosetta.string("notifications.editor_localisations_empty")}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let submitButton = (
        <>
            <div>{Rosetta.string("notifications.editor_save_preview_desc")}</div>
            <div className={"mt-2"}><button className={"btn btn-success"} onClick={() => submitNotificationOverNetwork()}>{Rosetta.string("notifications.editor_save_preview")}</button></div>
        </>
    );
    if (networkInFlight) {
        submitButton = (<LoadingSpinner inline={true} small={true} />);
    }

    return (
        <div className={"app-screen notification-editor-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("notifications.editor_title")} />
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <h3>{Rosetta.string("notifications.editor_basic_details")}</h3>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.editor_notification_title")}</label>
                                    <input type={"text"} className={"form-control"} value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.editor_notification_message")}</label>
                                    <textarea className={"form-control"} value={message} onChange={(e) => setMessage(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.editor_notification_url")}</label>
                                    <input type={"text"} className={"form-control"} value={url} placeholder={Rosetta.string("notifications.editor_notification_url_hint")} onChange={(e) => setUrl(e.target.value)} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <h3>{Rosetta.string("notifications.editor_audience")}</h3>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.editor_audience_countries")}</label>
                                    <div className={"form-control clickable"} onClick={() => setCountrySelectorShown(true)}>{Rosetta.string(countries.length > 0 ? "notifications.editor_audience_countries_count" : "notifications.editor_audience_countries_all", { count : countries.length }, countries.length)}</div>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.editor_audience_imeis")}</label>
                                    <div><small>{Rosetta.string("notifications.editor_audience_comma_seperated")}</small></div>
                                    <textarea className={"form-control"} value={imeis} placeholder={Rosetta.string("notifications.editor_audience_imeis_all")} onChange={(e) => setImeis(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.editor_audience_models")}</label>
                                    <div><small>{Rosetta.string("notifications.editor_audience_comma_seperated")}</small></div>
                                    <textarea className={"form-control"} value={models} placeholder={Rosetta.string("notifications.editor_audience_models_all")} onChange={(e) => setModels(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("notifications.editor_audience_serials")}</label>
                                    <div><small>{Rosetta.string("notifications.editor_audience_comma_seperated")}</small></div>
                                    <textarea className={"form-control"} value={serials} placeholder={Rosetta.string("notifications.editor_audience_serials_all")} onChange={(e) => setSerials(e.target.value)} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <h3>{Rosetta.string("notifications.editor_localisations")}</h3>

                    {localisationElems}

                    <div className={"row mt-2"}>
                        <div className={"col-12 text-center"}>
                            <button className={"btn btn-primary"} onClick={() => launchLanguageSelector()}>{Rosetta.string("notifications.editor_localisations_add")}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-8 col-lg-6"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    {submitButton}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CountrySelectorModal
                shown={countrySelectorShown}
                items={countries}
                multiSelect={true}
                callback={countrySelectorDidCallback} />

            <LanguageSelectorModal
                shown={languageSelectorShown}
                callback={languageSelectorDidCallback} />

            <NotificationPreviewModal
                shown={submitPreviewShown}
                summary={submitPreviewData}
                notification={submitPreviewNotification}
                callback={previewDidCallback} />

        </div>
    )

}