import "./PlaceholderText.css";

export const PlaceholderText = (props) => {

    let className = "";
    if (props.className !== undefined) {
        className = " " + props.className;
    }

    return (
        <span className={"placeholder-text-elem" + className}>&nbsp;</span>
    )

}