import React from 'react';
import Rosetta from "../rosetta/Rosetta";
import {LoadingSpinner} from "./loading/LoadingSpinner";

/**
 * ReactAwareComponent v1.0
 * -----------
 *
 * A Component which takes on some of the complexity of supporting Rosetta so you don't have to.
 *
 * -----------
 * HOW TO IMPLEMENT
 * -----------
 *
 * Stick the ReactAwareComponent somewhere at the top of your JSX DOM hierarchy and implement the callback.
 *
 * Wherever you place the ReactAwareComponent, simply define the callback like this:
 *
 * <RosettaAwareComponent callback={() => this.setState({rosettaMutationKey : Math.random()})}>
 *
 * Rosetta will only call the callback when Rosetta is ready and by mutating the state of the parent component, all
 * children to RosettaAwareComponent will then update as a result, which will force components to re-call Rosetta.string
 * and get new string values wherever called.
 *
 */
export default class RosettaAwareComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        let rosettaState = Rosetta.getInstance().getState();
        let rosettaReady = rosettaState === Rosetta.STATE_READY;

        this.state = {
            rosettaReady
        }
    }

    componentDidMount() {
        Rosetta.getInstance().addCallback((state) => {
            let rosettaReady = state === Rosetta.STATE_READY;

            this.setState({
                rosettaReady
            }, () => {
                this.key = Math.random();

                if (this.state.rosettaReady) {
                    if (this.props.callback !== undefined) {
                        this.props.callback();
                    }
                }
            });
        });
    }

    componentWillUnmount() {
        window._rosettaDidUpdate = undefined;
    }

    render() {
        if (!this.state.rosettaReady) {
            let loadingUI = (<LoadingSpinner />);
            if (this.props.loadingComponent !== undefined) {
                loadingUI = this.props.loadingComponent;
            }

            return loadingUI;
        }

        return this.props.children;
    }
}