export default class WindowUtil {

    static lockBodyScroll = () => {
        if (!document.body.parentElement.classList.contains("body-locked")) {
            document.body.parentElement.classList.add("body-locked");
        }
    }

    static unlockBodyScroll = () => {
        if (document.body.parentElement.classList.contains("body-locked")) {
            document.body.parentElement.classList.remove("body-locked");
        }
    }

    static scrollToTop =() => {
        window.scrollTo(0,0);
    }

}