import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import Rosetta from "../../../rosetta/Rosetta";
import {useState} from "react";
import {DataManager} from "../../../data/DataManager";

export const UserAccountActions = {
    SIGN_OUT : "signout"
};

export const UserAccountModal = (props) => {

    const {shown} = props;

    const [forceDismiss, setForceDismiss] = useState(false);

    function handleCallback(action, data) {
        if (props.callback) {
            props.callback(action, data);
        }
    }

    if (!shown) return [];

    const user = DataManager.getUser();

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("settings.title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>

                    <div className={"card"}>
                        <div className={"card-body"}>

                            <div className={"row"}>
                                <div className={"col-12 text-center"}>
                                    <strong>{(user ? Rosetta.string("common.name_format", { given_name : user.givenName, family_name : user.familyName }) : "?")}</strong>
                                </div>

                                <div className={"col-12 mt-2 text-center"}>
                                    <button className={"btn btn-primary"} onClick={() => handleCallback(UserAccountActions.SIGN_OUT)}>{Rosetta.string("settings.sign_out")}</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </Offcanvas>

    )

}