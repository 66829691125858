import {Offcanvas, OffcanvasGravity} from "../../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import WindowUtil from "../../../util/WindowUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../../toast/TokyoToaster";
import Validator from "../../../util/Validator";
import {LoadingSpinner} from "../../loading/LoadingSpinner";

export const UserEditorModal = (props) => {

    const {shown} = props;
    const {userId} = props;

    const [user, setUser] = useState(null);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [userEmail, setUserEmail] = useState("");
    const [userGivenName, setUserGivenName] = useState("");
    const [userFamilyName, setUserFamilyName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userPasswordRetype, setUserPasswordRetype] = useState("");
    const [userActive, setUserActive] = useState("1");

    const [forceDismiss, setForceDismiss] = useState(false);
    const [callbackData, setCallbackData] = useState(null);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (shown) {
            if (userId && userId !== "new") {
                fetchUserFromNetwork();
            }

            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setForceDismiss(false);
            setUser(null);
            setUserEmail("");
            setUserGivenName("");
            setUserFamilyName("");
            setUserPassword("");
            setUserPasswordRetype("");
            setUserActive("");
            setError(null);
            setCallbackData(null);
        }
    }, [shown]);

    useEffect(() => {
        if (user) {
            setUserEmail(user.emailAddress);
            setUserGivenName(user.givenName);
            setUserFamilyName(user.familyName);
            setUserActive(user.active);
        }
    }, [user]);

    function handleCallback(action) {
        if (props.callback) {
            props.callback(action, callbackData);
        }
    }

    // Network

    function fetchUserFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            ids : [userId],
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.users.getUsers, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setUser(resp.data.data[0]);
                    }
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setError(API.defaultError("UE1000C"));
            });
    }

    function submitUserOverNetwork() {
        if (networkInFlight) return;

        let validationResult = Validator.validateCreateFormData({
            id : (user ? user.id : undefined),
            userEmail, userGivenName, userFamilyName,
            userPassword, userActive
        }, [
            Validator.rule("id", "int", "", "id", true),
            Validator.rule("userEmail", "string", Rosetta.string("users.editor_email"), "emailAddress"),
            Validator.rule("userGivenName", "string", Rosetta.string("users.editor_given_name"), "givenName"),
            Validator.rule("userFamilyName", "string", Rosetta.string("users.editor_family_name"), "familyName"),
            Validator.rule("userPassword", "string", Rosetta.string("users.editor_password"), "password", user),
            Validator.rule("userActive", "string", Rosetta.string("users.editor_active"), "active", true)
        ]);

        if (!validationResult.success) {
            setError(validationResult.error);
            return;
        }

        if (userPassword !== "" && userPassword !== userPasswordRetype) {
            setError(Rosetta.string("users.editor_error_password_mismatch"));
            return;
        }

        setNetworkInFlight(true);

        Axios.post(ENDPOINTS.users.submitUser, validationResult.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("users.editor_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setCallbackData(true);
                    setForceDismiss(true);
                } else {
                    setError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                setError(API.defaultError("US1000C"));
            });
    }

    // Render

    if (!shown) return [];

    let passwordExplain = [];
    if (user) {
        passwordExplain = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"alert alert-info"}>
                        {Rosetta.string("users.editor_password_explain")}
                    </div>
                </div>
            </div>
        )
    }

    let submitButton = (<button className={"btn btn-success"} onClick={() => submitUserOverNetwork()}>{Rosetta.string("common.save")}</button>);
    if (networkInFlight) {
        submitButton = (<LoadingSpinner />);
    }

    let errorElem = [];
    if (error !== null) {
        errorElem = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"alert alert-danger"}>
                        {error}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("users.editor_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("users.editor_email")}</label>
                    <input type={"text"} className={"form-control"} value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("users.editor_given_name")}</label>
                    <input type={"text"} className={"form-control"} value={userGivenName} onChange={(e) => setUserGivenName(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("users.editor_family_name")}</label>
                    <input type={"text"} className={"form-control"} value={userFamilyName} onChange={(e) => setUserFamilyName(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <div className={"card"}>
                        <div className={"card-body"}>

                            {passwordExplain}

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("users.editor_password")}</label>
                                    <input type={"password"} className={"form-control"} value={userPassword} onChange={(e) => setUserPassword(e.target.value)} />
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>{Rosetta.string("users.editor_password_retype")}</label>
                                    <input type={"password"} className={"form-control"} value={userPasswordRetype} onChange={(e) => setUserPasswordRetype(e.target.value)} />
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("users.editor_active")}</label>
                    <select className={"form-select"} value={userActive} onChange={(e) => setUserActive(e.target.value)}>
                        <option value={"0"}>{Rosetta.string("common.no")}</option>
                        <option value={"1"}>{Rosetta.string("common.yes")}</option>
                    </select>
                </div>
            </div>

            {errorElem}

            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    {submitButton}
                </div>
            </div>

        </Offcanvas>
    )

}