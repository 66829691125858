import logo from "../../../assets/images/logo.png";
import space from "../../../assets/images/background-earth.jpeg";
import {ImageUtil} from "../../../util/ImageUtil";
import {useState} from "react";
import Rosetta from "../../../rosetta/Rosetta";
import {EventUtil} from "../../../util/EventUtil";

import "./LoginScreen.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";
import {AlertModal} from "../../alertmodal/AlertModal";
import {ForgottenPasswordModal} from "./ForgottenPasswordModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";

export const LoginScreen = (props) => {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [forgottenPasswordShown, setForgottenPasswordShown] = useState(false);

    function forgottenPasswordDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setForgottenPasswordShown(false);
        }
    }

    function submitLoginOverNetwork(e) {
        EventUtil.cancel(e);

        if (networkInFlight) return;
        setNetworkInFlight(true);

        const data = {
            emailAddress : username,
            password
        };

        Axios.post(ENDPOINTS.auth.login, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    DataManager.setSessionToken(resp.data.sessionToken);
                    DataManager.setUser(resp.data.user);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(API.defaultError("LI1000C"));
            });
    }

    return (
        <div className={"container-flex login-screen"}>
            <div className={"row"}>
                <div className={"col-1 col-md-4 col-lg-6 fancy-panel"}>
                    <div className={"fancy-image"} style={{background : ImageUtil.background(space)}} />
                    <div className={"vertical-shadow"} />
                </div>

                <div className={"col-11 col-md-8 col-lg-6 form-area"}>
                    <div className={"row"}>
                        <div className={"col-1 col-md-3 col-lg-4"} />
                        <div className={"col-9 col-md-8 col-lg-5"}>
                            <form onSubmit={submitLoginOverNetwork}>
                                <div className={"logo"} style={{backgroundImage : ImageUtil.background(logo)}} />

                                <div className={"form-row"}>
                                    <label>{Rosetta.string("login.username")}</label>
                                    <input type={"text"} className={"form-control"} value={username} onChange={(e) => setUsername(e.target.value)} />
                                </div>

                                <div className={"form-row"}>
                                    <label>{Rosetta.string("login.password")}</label>
                                    <input type={"password"} className={"form-control"} value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>

                                <div className={"form-row"}>
                                    <button type={"submit"} className={"btn btn-primary"}>{Rosetta.string("login.login")}</button>
                                </div>

                                <div className={"form-row"}>
                                    <a href={"#"} onClick={(e) => { EventUtil.cancel(e); setForgottenPasswordShown(true); }}>{Rosetta.string("login.forgotten_password")}</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <ForgottenPasswordModal
                shown={forgottenPasswordShown}
                callback={forgottenPasswordDidCallback} />
        </div>
    )

}