export const ImageUtil = {
    /**
     * Fixes a Base 64-encoded string so it can be displayed in an img tag or background image
     * @param rawBase64         Raw Base 64 encoded string
     * @returns {string|*}      Output string
     */
    createBase64Url : (rawBase64) => {
        if (rawBase64 !== undefined && rawBase64 !== null) {
            // Check to see if Base 64 is already properly formed.
            if (rawBase64.startsWith("data:")) {
                return rawBase64;
            }

            // Base64 images are not encrypted, so compressed data will have tell-tale signature
            // at the start of the string. We'll use this to find some common header signatures
            // and then add the relevant header information to a raw Base 64 string so it can
            // be displayed in an img tag or background image of an HTML element.
            const headerSignatures = ImageUtil.signatures;

            for (let i = 0; i < headerSignatures.length; i++) {
                let sig = headerSignatures[i];
                if (rawBase64.startsWith(sig.signature)) {
                    return "data:" + sig.mime + ";base64," + encodeURI(rawBase64);
                }
            }
        }

        // If we fail to identify the mime, just return the input string
        return rawBase64;
    },
    /**
     * Determines if the given data blob is Base 64 or not
     * @param rawBase64     String to determine
     * @returns {boolean}   If the given string is base 64 or not
     */
    isBase64Image : (rawBase64) => {
        if (rawBase64 !== undefined && rawBase64 !== null) {
            if (rawBase64.startsWith("data:")) {
                return true;
            }

            for (let i = 0; i < ImageUtil.signatures.length; i++) {
                let sig = ImageUtil.signatures[i];
                if (rawBase64.startsWith(sig.signature)) {
                    return true;
                }
            }
        }

        return false;
    },
    /**
     * Wraps a URL in a url() notation for CSS
     * @param imageUri      Image URL to wrap
     * @returns {string}    Wrapped Image URL
     */
    background : (imageUri) => {
        return "url(" + imageUri + ")";
    },
    /**
     * Determines the mime of a file with the given path.
     *
     * NOTE: This is not foolproof. We are ONLY going on the extension of the file. If a file doesn't have a coherent
     * extension we will be unable to determine the type.
     *
     * @param path              Path to parse
     * @returns {string|null}   The mime type or null if no matching image mime is found
     */
    getMimeFromPath : (path) => {
        let fileParts = path.split(".");
        if (fileParts.length > 0) {
            let fileExt = fileParts[fileParts.length - 1].toLowerCase();

            for (let i = 0; i < ImageUtil.mimes.length; i++) {
                let mime = ImageUtil.mimes[i];
                for (let x = 0; x < mime.extensions.length; x++) {
                    let ext = mime.extensions[i];
                    if (fileExt === ext) {
                        return mime.mime;
                    }
                }
            }

        }
        return null;
    },
    /**
     * Determines if the given Mime Type is able to be displayed in a web browser <img> tag
     * @param inMime        Mime Type to evaluate
     * @returns {boolean}   If the mime was an image that can be displayed
     */
    isMimeADisplayableImage : (inMime) => {
        if (inMime !== undefined && inMime !== null) {
            inMime = inMime.toLowerCase();
            for (let i = 0; i < ImageUtil.mimes.length; i++) {
                let mime = ImageUtil.mimes[i];
                if (inMime === mime.mime) {
                    return true;
                }
            }
        }
        return false;
    },
    signatures : [
        { signature : "JVBERi0", mime : "application/pdf" },
        { signature : "R0lGODdh", mime : "image/gif" },
        { signature : "R0lGODlh", mime : "image/gif" },
        { signature : "iVBORw0KGgo", mime : "image/png" },
        { signature : '/9j/', mime : "image/jpg" },
        { signature : '/', mime : "image/jpeg" }
    ],
    mimes : [
        { mime : "image/jpeg", extensions : ["jpg", "jpeg"] },
        { mime : "image/png", extensions: ["png"] },
        { mime : "image/gif", extensions: ["gif"] },
        { mime : "image/webp", extensions: ["webp"] },
        { mime : "image/tiff", extensions : ["tiff"] },
        { mime : "application/pdf", extensions: ["pdf"] }
    ]
};