import "./TokyoHeaderBar.css";
import {NavLink} from "react-router-dom";

import {ImageUtil} from "../../util/ImageUtil";

export const TokyoHeaderBarActions = {
    SIGNOUT : "signout",
    PROFILE_CLICKED : "profile_click"
};

export const HeaderNav = (title, url, onClick, active) => {
    return {
        title,
        to : url,
        active,
        onClick
    };
}

export const TokyoHeaderBar = (props) => {

    const {bare} = props;

    let navItems = [];
    if (props.hasOwnProperty("items")) {
        navItems = props.items;
    }

    function actionCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    let customNavArea = null;
    if (props.hasOwnProperty("navigationContent")) {
        customNavArea = props.navigationContent;
    }

    let logoBackground = null;
    let logoExtraCSS = "";
    let logoContent = [];
    if (props.logo !== undefined) {
        if (props.logo.image !== undefined) {
            logoBackground = props.logo.image;
            logoExtraCSS = " logo-rectangle";
        }

        if (props.logo.text !== undefined) {
            logoContent = (
                <div className={"logo-text"}>{props.logo.text}</div>
            )
        }
    }

    let logoTag = [];
    if (process.env.REACT_APP_LOGO_TAG) {
        logoTag = (<div className={"tag-text"}>{process.env.REACT_APP_LOGO_TAG}</div>);
    }

    let logoArea = (
        <div className={"logo-area" + logoExtraCSS} style={{backgroundImage : "url(" + logoBackground + ")"}}>
            {logoContent}
            {logoTag}
        </div>
    )

    let navAreaContent = [];
    if (navItems.length > 0) {
        navAreaContent = navItems.map((item) => {
            let extraClass = "";
            if (item.active) {
                extraClass = " active";
            }

            let title = "";
            if (item.title !== undefined) {
                title = item.title;
            }

            let href = "#";
            if (item.href !== undefined) {
                href = item.href;
            } else if (item.to !== undefined) {
                href  = item.to;
            }

            return (
                <NavLink className={"nav-pill" + extraClass} to={href} activeClassName={"active"} onClick={item.onClick}>{title}</NavLink>
            )
        });
    } else if (customNavArea !== null) {
        navAreaContent = customNavArea;
    }

    let accountAreaElem = [];
    if (bare === undefined || !bare) {
        accountAreaElem = (
            <div className={"account-area"}>
                <div className={"account-circle"} style={{backgroundImage : ImageUtil.background(null) }} onClick={() => actionCallback(TokyoHeaderBarActions.PROFILE_CLICKED)} />
            </div>
        );
    }

    return (
        <div className={"tokyo-header-bar"}>
            <div className={"row align-items-center"}>
                <div className={"col-5 col-md-4 col-lg-3"}>
                    {logoArea}
                </div>
                <div className={"col-5 col-md-6 col-lg-7"}>
                    <div className={"navigation-area"}>
                        {navAreaContent}
                    </div>
                </div>
                <div className={"col-2 text-end"}>
                    {accountAreaElem}
                </div>
            </div>
        </div>
    )

}