import BaseComponent from "./BaseComponent";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {InitNavigator} from "../util/Navigator";
import RosettaAwareComponent from "./RosettaAwareComponent";
import {TokyoToaster} from "./toast/TokyoToaster";
import AlertModal, {AlertModalComponent} from "./alertmodal/AlertModal";
import {useEffect, useRef, useState} from "react";
import {CommonUtil} from "../util/CommonUtil";
import {DataManager} from "../data/DataManager";
import {API} from "../network/API";
import {Comment} from "./comment/Comment";
import {LoginScreen} from "./screens/login/LoginScreen";
import {HeaderNav, TokyoHeaderBar, TokyoHeaderBarActions} from "./headerbar/TokyoHeaderBar";

import logoImage from "../assets/images/logo.png";
import Rosetta from "../rosetta/Rosetta";
import {NotificationListScreen} from "./screens/home/NotificationListScreen";
import {NotificationEditorScreen} from "./screens/home/NotificationEditorScreen";
import {BaseModalActions} from "./alertmodal/BaseModal";
import {NotificationDetailScreen} from "./screens/home/NotificationDetailScreen";
import {UserListScreen} from "./screens/users/UserListScreen";
import {UserAccountActions, UserAccountModal} from "./screens/settings/UserAccountModal";
import {OffcanvasActions} from "./offcanvas/Offcanvas";
import {PasswordResetScreen} from "./screens/login/PasswordResetScreen";

export const MainComponent = (props) => {

    const [alertModalState, setAlertModalState] = useState({shown:false});
    const [user, setUser] = useState(null);
    const [rosettaMutationKey, setRosettaMutationKey] = useState(null);

    const [userSettingsShown, setUserSettingsShown] = useState(false);

    const dataManagerCallback = useRef(null);

    useEffect(() => {
        let user = DataManager.getUser();
        if (user) {
            setUser(user);
        }

        let sessionToken = DataManager.getSessionToken();
        if (sessionToken !== null) {
            API.setAuthenticationToken(sessionToken);
        }

        API.setAuthFailureCallback(() => {
            userDidLogout();
        });

        dataManagerCallback.current = (keyName, value) => {
            if (keyName === DataManager.keys.user) {
                if (value !== user) {
                    userDidChange(value)
                }
            } else if (keyName === DataManager.keys.sessionToken) {
                API.setAuthenticationToken(value);
            }
        }

        DataManager.addCallback(dataManagerCallback.current);

        // Set up global Alert Dialog callbacks
        if (!window.hasOwnProperty("_showModal")) {
            window._showModal = (title, message, buttons, icon) => {
                showAlertModal(title, message, buttons, icon);
            }
        }

        if (!window.hasOwnProperty("_hideModal")) {
            window._dismissModal = () => {
                const ams = CommonUtil.cloneObject(alertModalState);
                ams.forceDismiss = true;
                setAlertModalState(ams);
            }
        }

        return () => {
            if (dataManagerCallback.current !== null) {
                DataManager.removeCallback(dataManagerCallback.current);
            }
        }
    }, []);

    // ALERT MODAL

    function showAlertModal(title, message, buttons, icon) {
        setAlertModalState({
            shown : true,
            title,
            message,
            buttons,
            icon
        });
    }

    function alertModalDidCallback(action) {
        if (action === BaseModalActions.CLOSE) {
            setAlertModalState({shown: false});
        }
    }

    // USER

    function userDidChange(user) {
        setUser(user);
        if (!user) {
            userDidLogout();
        }
    }

    function userDidLogout() {
        DataManager.clear();

        // Force page refresh now
        //window.location.href = "/";
    }

    function headerBarDidCallback(action) {
        if (action === TokyoHeaderBarActions.PROFILE_CLICKED) {
            setUserSettingsShown(true);
        }
    }

    function userSettingsDidCallback(action, data) {
        if (action === UserAccountActions.SIGN_OUT) {
            DataManager.clear();
            setUser(null);
            // Force page refresh at this point
            window.location.href = "/";
        } else if (action === OffcanvasActions.CLOSE) {
            setUserSettingsShown(false);
        }
    }

    // RENDER

    let mainContent = [];

    if (user) {
        console.log("USER!");
        mainContent = (
            <>
                <div className={"header-wrapper"}>
                    <div className={"container"}>
                        <TokyoHeaderBar
                            logo={{image : logoImage }}
                            items={[
                                HeaderNav(
                                    Rosetta.string("navigation.notifications"),
                                    "/"
                                ),
                                HeaderNav(
                                    Rosetta.string("navigation.users"),
                                    "/users"
                                )
                            ]}
                            callback={headerBarDidCallback} />
                    </div>
                </div>

                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <Routes>
                                <Route
                                    path={""}
                                    element={<NotificationListScreen />} />

                                <Route
                                    path={"/notification/create"}
                                    element={<NotificationEditorScreen />} />

                                <Route
                                    path={"/notification/detail/:identifier"}
                                    element={<NotificationDetailScreen />} />

                                <Route
                                    path={"/users"}
                                    element={<UserListScreen />} />

                            </Routes>
                        </div>
                    </div>

                    <UserAccountModal
                        shown={userSettingsShown}
                        callback={userSettingsDidCallback} />
                </div>
            </>
        )
    } else {
        mainContent = (
            <Routes>
                <Route
                    path={""}
                    element={<LoginScreen />} />

                <Route
                    path={"/forgot/:token"}
                    element={<PasswordResetScreen />} />
            </Routes>
        )
    }

    return (
        <BrowserRouter>
            <InitNavigator />

            <RosettaAwareComponent callback={() => setRosettaMutationKey(Math.random())}>
                <TokyoToaster>
                    {mainContent}
                </TokyoToaster>
            </RosettaAwareComponent>

            <AlertModalComponent
                shown={alertModalState.shown}
                icon={alertModalState.icon}
                title={alertModalState.title}
                message={alertModalState.message}
                buttons={alertModalState.buttons}
                forceDismiss={alertModalState.forceDismiss}
                callback={alertModalDidCallback} />

        </BrowserRouter>
    );

}